import React, { useState } from "react";
import { Input, Modal, Spin, Tooltip, message, notification } from "antd";
import { BiInfoCircle, BiTrash } from "react-icons/bi";
import { LoadingOutlined } from "@ant-design/icons";
import { GetSignedUrl } from "../../../functions/getSignedAudioUrl";
import { DoSignedAudioUpload } from "../../../functions/doSignedAudioUpload";
import { BaseServices } from "../../../helpers/baseServices";
import { global_variables } from "../../../helpers/globalVariables";
import BookCategorySelector from "../bookCategorySelector";
import LanguageSelector from "../languageSelector";
import CoverImageUploader from "../../mediaUploader/coverImageUploader";
import AudioSampleUploader from "../../mediaUploader/audioSampleUploader";
import BannerImageUploader from "../../mediaUploader/bannerImageUploader";
import SampleVideoComponent from "../sampleVideoComponent";
import FinishPublishingSkit from "./finishPublishingSkit";
import { StorageBox } from "../../../helpers/stroage";

interface modalProps {
  isOpened: boolean;
  handleClose: () => void;
}

const PublishSkitModal = ({ isOpened, handleClose }: modalProps) => {
  const userData = StorageBox.retrieveUserData();
  const [theTitle, setTheTitle] = useState("");
  const [theAbout, setTheAbout] = useState("");
  const [bookCategories, setBookCategories] = useState<any>([]);
  const [bookLanguage, setBookLanguage] = useState("");
  const [bookPrice, setBookPrice] = useState("");

  const [bookCoverFile, setBookCoverFile] = useState<File | null>(null);
  const [bookCoverUrl, setBookCoverUrl] = useState<string | null>(null);

  const [bookBannerFile, setBookBannerFile] = useState<File | null>(null);
  const [bookBannerUrl, setBookBannerUrl] = useState<string | null>(null);

  const [selectedAudioFile, setSelectedAudioFile] = useState<File | null>(null);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [audioFileName, setAudioFileName] = useState<any>("");

  const [isSavingBook, setIsSavingBook] = useState(false);
  const [tempBookData, setTempBookData] = useState<any>(null);

  //story finish modal
  const [openFinishStory, setOpenFinishStory] = useState(false);
  const handleOpenFinishStory = () => {
    setOpenFinishStory(true);
  };
  const handleCloseFinishStory = () => {
    setOpenFinishStory(false);
  };

  //createAndGoToNext
  const createAndGoToNext = () => {
    if (!theTitle) {
      message.warning("Book title field cannot be empty");
    } else if (!theAbout) {
      message.warning("About field cannot be empty");
    } else if (bookCategories.length === 0) {
      message.warning("Add at least one category");
    } else if (!bookLanguage) {
      message.warning("Language field cannot be empty");
    } else if (!bookCoverUrl) {
      message.warning("Add a cover image");
    } else if (!audioUrl) {
      message.warning("Add sample audio");
    } else {
      let data = new FormData();
      data.append("title", theTitle);
      data.append("author", userData?.profile?.name || "");
      // data.append("author_notes", authNotes);
      // data.append("narrated_by", narrator);
      data.append("about", theAbout);
      data.append("status", "Draft");
      data.append("category_ids", bookCategories);
      data.append("language_id", bookLanguage);
      bookPrice && data.append("price", parseFloat(bookPrice).toFixed(2));
      data.append("type", "Movie");
      bookCoverFile && data.append("media", bookCoverFile);
      bookBannerFile && data.append("banner", bookBannerFile);
      // data.append("publish_to_feed", publishToFeed);

      setIsSavingBook(true);

      //get audio upload url
      GetSignedUrl(audioFileName, "audiobook_sample")
        .then((signedRes) => {
          console.log("signedRes:", signedRes);
          //signed upload
          DoSignedAudioUpload(
            signedRes?.upload_url,
            selectedAudioFile,
            signedRes?.content_type
          )
            .then((uploadRes: any) => {
              console.log("uploadRes:", uploadRes);
              if (uploadRes?.status === 200) {
                data.append("audio_sample", signedRes?.media_url);

                //create actual book
                BaseServices.post(`${global_variables.create_audio_book}`, data)
                  .then((response) => {
                    console.log("create_story_success:", response);
                    setTempBookData(response?.data);
                    notification.success({
                      message: "New story was saved as draft!",
                    });
                    setIsSavingBook(false);
                    handleOpenFinishStory();
                  })
                  .catch((error) => {
                    setIsSavingBook(false);
                    console.log("create_story_error:", error);
                    notification.error({
                      message:
                        error?.details ||
                        error?.response?.data?.detail ||
                        "An error occured, please try again",
                    });
                  });
              } else {
                setIsSavingBook(false);
                message.error(
                  "Audio upload failed, please restart the process."
                );
              }
            })
            .catch((uploadErr) => {
              //signed upload err
              setIsSavingBook(false);
              console.log("uploadErr:", uploadErr);
              message.error(
                uploadErr?.details ||
                  uploadErr?.response?.data?.error ||
                  "An error occured while uploading audio, please try again"
              );
            });
        })
        .catch((signedErr) => {
          //get audio upload url error
          setIsSavingBook(false);
          console.log("signedErr:", signedErr);
          message.error(
            signedErr?.details ||
              signedErr?.response?.data?.error ||
              "An error occured while uploading audio, please try again"
          );
        });
    }
  };

  //handleBookCoverSelect
  const handleBookCoverSelect = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setBookCoverFile(file);
      const url = URL.createObjectURL(file);
      setBookCoverUrl(url);
    }
  };

  //handleBookBannerSelect
  const handleBookBannerSelect = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setBookBannerFile(file);
      const url = URL.createObjectURL(file);
      setBookBannerUrl(url);
    }
  };

  //handleAudioFileChange
  const handleAudioFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      console.log("audioExtension:", file);
      setAudioFileName(file?.name);
      setSelectedAudioFile(file);
      const url = URL.createObjectURL(file);
      setAudioUrl(url);
    }
  };

  return (
    <>
      <Modal
        width={600}
        open={isOpened}
        onCancel={handleClose}
        footer={null}
        maskClosable={false}
        keyboard={false}
      >
        <div className="text-black text-opacity-[80%] my-5 px-5">
          <p className="text-[26px] font-[700]">Publish A Skit / Movie</p>

          <div>
            <div className="mt-[37px]">
              <label htmlFor="title" className="text-[16px] font-[400]">
                Title
              </label>
              <Input
                id="title"
                className={`w-full h-[52px] rounded-[20px] mt-[7px]`}
                value={theTitle}
                onChange={(e) => setTheTitle(e.target.value)}
              />
            </div>

            <div className="mt-5">
              <label htmlFor="about" className="text-[16px] font-[400]">
                About the Skit / Movie
              </label>
              <Input.TextArea
                id="about"
                className={`w-full rounded-[20px] mt-[7px]`}
                style={{ height: "104px" }}
                value={theAbout}
                onChange={(e) => setTheAbout(e.target.value)}
              />
            </div>

            <div className="mt-5">
              <div className="flex justify-between items-center">
                <label htmlFor="category" className="text-[16px] font-[400]">
                  Category
                </label>
                <Tooltip
                  title="You can select multiple categories where applicable."
                  placement="top"
                >
                  <BiInfoCircle className="h-5 w-5 cursor-pointer" />
                </Tooltip>
              </div>
              <div className="w-full h-[52px] rounded-[20px] mt-[7px] border-[1px] border-black border-opacity-[20%]">
                <BookCategorySelector
                  onChange={(selected) => setBookCategories(selected)}
                />
              </div>
            </div>

            <div className="mt-5">
              <label htmlFor="language" className="text-[16px] font-[400]">
                Language
              </label>
              <div className="w-full h-[52px] rounded-[20px] mt-[7px] border-[1px] border-black border-opacity-[20%]">
                <LanguageSelector
                  onChange={(selected: any) => setBookLanguage(selected)}
                />
              </div>
            </div>

            <div className="mt-5">
              <label htmlFor="price" className="text-[16px] font-[400]">
                Price
              </label>
              <Input
                id="price"
                className={`w-full h-[52px] rounded-[20px] mt-[7px]`}
                value={bookPrice}
                onChange={(e) => setBookPrice(e.target.value)}
              />
            </div>

            {/* cover image */}
            <div className="mt-5">
              <div className="flex justify-between items-center">
                <small className="text-[16px] font-[400]">
                  Upload cover image{" "}
                  <small className="text-xs text-red-500">required</small>
                </small>
                <Tooltip
                  title="Required dimension 226px by 226px"
                  placement="top"
                >
                  <BiInfoCircle className="h-5 w-5 cursor-pointer" />
                </Tooltip>
              </div>
              <CoverImageUploader
                onChange={handleBookCoverSelect}
                placeholder="Select file to upload"
              />
              <div className="flex justify-between mt-2">
                {bookCoverFile && <p>Selected File: {bookCoverFile.name}</p>}
                {bookCoverUrl && (
                  <img
                    className="h-[100px] w-[100px] object-contain border border-gray-500 rounded"
                    src={bookCoverUrl}
                    alt="Uploaded Cover"
                  />
                )}
              </div>
            </div>

            {/* banner image */}
            <div className="mt-5">
              <div className="flex justify-between items-center">
                <small className="text-[16px] font-[400]">
                  Upload banner image
                </small>
                <Tooltip
                  title="Required dimension 844px by 311px"
                  placement="top"
                >
                  <BiInfoCircle className="h-5 w-5 cursor-pointer" />
                </Tooltip>
              </div>
              <BannerImageUploader
                onChange={handleBookBannerSelect}
                placeholder="Select file to upload"
              />
              <div className="flex justify-between mt-2">
                {bookBannerFile && <p>Selected File: {bookBannerFile.name}</p>}
                {bookBannerUrl && (
                  <img
                    className="h-[100px] w-[100px] object-contain border border-gray-500 rounded"
                    src={bookBannerUrl}
                    alt="Uploaded Banner"
                  />
                )}
              </div>
            </div>

            {/* sample audio */}
            <div className="mt-5">
              <small className="text-[16px] font-[400]">
                Upload Skit / Movie Sample (Trailer)
                <small className="text-xs text-red-500">required</small>
              </small>
              <AudioSampleUploader
                id="story"
                placeholder="Select file to upload"
                onChange={handleAudioFileChange}
              />
              {audioUrl && (
                <div className="mt-2 flex items-center gap-2">
                  <SampleVideoComponent sampleVideo={audioUrl} />
                  <BiTrash
                    className="h-5 w-5 text-red-500 cursor-pointer"
                    onClick={() => {
                      setSelectedAudioFile(null);
                      setAudioUrl(null);
                    }}
                  />
                </div>
              )}
            </div>

            <div className="mt-[20px]">
              <button
                className="w-full h-[52px] rounded-[40px] bg-gradient-to-r from-[#2E11CD] to-[#BE24BA] text-white flex justify-center items-center"
                type="submit"
                onClick={createAndGoToNext}
                disabled={isSavingBook}
              >
                {isSavingBook ? (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{ fontSize: 24, color: "#fff" }}
                        spin
                      />
                    }
                  />
                ) : (
                  <p className="text-[16px] font-[700]">Next</p>
                )}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {/* finish publish story modal */}
      {openFinishStory && (
        <FinishPublishingSkit
          isOpened={openFinishStory}
          handleClose={handleCloseFinishStory}
          tempBookData={tempBookData}
          handleClosePrev={handleClose}
        />
      )}
    </>
  );
};

export default PublishSkitModal;
