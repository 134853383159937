import React, { useState } from "react";
import { VscBook } from "react-icons/vsc";
import { BiBookReader } from "react-icons/bi";
import { LuPodcast } from "react-icons/lu";
import PublishedStories from "./tabs/publishedStories";
import PublishedBooks from "./tabs/publishedBooks";
import PublishedPodcasts from "./tabs/publishedPodcasts";
import { MdOndemandVideo } from "react-icons/md";
import PublishedSkits from "./tabs/publishedSkits";

const NewPublications = () => {
  const [activeTab, setActiveTab] = useState(0);

  //tabNames
  const tabNames = [
    {
      name: "Stories",
      icon: <BiBookReader className="h-5 w-5" />,
    },
    {
      name: "Books",
      icon: <VscBook className="h-5 w-5" />,
    },
    {
      name: "Podcasts",
      icon: <LuPodcast className="h-5 w-5" />,
    },
    {
      name: "SKITS",
      icon: <MdOndemandVideo className="h-5 w-5" />,
    },
  ];

  //tabComponents
  const tabComponents = [
    <PublishedStories />,
    <PublishedBooks />,
    <PublishedPodcasts />,
    <PublishedSkits />,
  ];

  return (
    <>
      <div className="w-full flex gap-10 items-center border-b border-gray-300 pb-2">
        {tabNames.map((tab, i) => (
          <div
            key={i}
            className={`${
              activeTab === i
                ? "bg-defaultPurple text-white border-defaultPurple"
                : ""
            } flex gap-3 items-center w-fit px-5 h-[40px] rounded border cursor-pointer`}
            onClick={() => setActiveTab(i)}
          >
            <div>{tab?.icon}</div>
            <p className="text-[14px] font-[500]">{tab?.name}</p>
          </div>
        ))}
      </div>

      {/* tab components */}
      <div className="mt-5">{tabComponents[activeTab]}</div>
    </>
  );
};

export default NewPublications;
