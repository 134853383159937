import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Dashboard from "./pages/dashboard/dashboard";
import { GeneralContextProvider } from "./context/generalContext";
import AllTransactions from "./pages/earnings/allTransactions";
import MyPublications from "./pages/myPublications/myPublications";
import MyWallet from "./pages/myWallet/myWallet";
import AnalyticsPage from "./pages/analytics/analyticsPage";
import SigninPage from "./pages/auth/signin/signin";
import OnboardingUser from "./pages/auth/onboard/onboarding";
import Authorization from "./router/authorization";
import ProtectedRoute from "./HOC/ProtectedRoute";

// tests

function App() {

  return (
    <>
      <GeneralContextProvider>
        <Router>
          <Routes>
            <Route path="/*" element={<h4>Page not found!</h4>} />
            <Route path="/" element={<SigninPage />} />
            <Route path="/authorization/:id" element={<ProtectedRoute><Authorization /></ProtectedRoute>} />
            <Route path="/onboarding" element={<ProtectedRoute><OnboardingUser /></ProtectedRoute>} />
            <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
            <Route path="/all-transactions" element={<ProtectedRoute><AllTransactions /></ProtectedRoute>} />
            <Route path="/analytics" element={<ProtectedRoute><AnalyticsPage /></ProtectedRoute>} />
            <Route path="/my-publications" element={<ProtectedRoute><MyPublications /></ProtectedRoute>} />
            <Route path="/my-wallet" element={<ProtectedRoute><MyWallet /></ProtectedRoute>} />
          </Routes>
        </Router>
      </GeneralContextProvider>
    </>
  );
}

export default App;
