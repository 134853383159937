"use client";

import React, { useState } from "react";
import { Input, Modal, Spin, message } from "antd";
import { useFormik } from "formik";
import { LoadingOutlined } from "@ant-design/icons";
import { BaseServices } from "../../../helpers/baseServices";
import { global_variables } from "../../../helpers/globalVariables";
import { CreateChapterSchema } from "../../../form-schema/story.schema";

interface modalProps {
  isOpened: boolean;
  handleCloseAddChapter: () => void;
  bookId: any;
  handleReFetch: () => void;
  theType?: string;
}

const AddStoryChapter = ({
  isOpened,
  handleCloseAddChapter,
  bookId,
  handleReFetch,
  theType,
}: modalProps) => {
  const [isCreatingChapter, setIsCreatingChapter] = useState(false);

  //handleCreateChapter
  const onSubmit = async () => {
    if (values?.number && values?.title) {
      const chapter_data = {
        title: values?.title,
        number: parseInt(values?.number),
      };

      setIsCreatingChapter(true);
      try {
        const response = await BaseServices.post(
          `${global_variables?.retrieve_single_book}/${bookId}/chapter/create/`,
          chapter_data
        );
        console.log(response?.data);
        message.success(
          `New ${
            theType === "Movie" ? "season" : "chapter"
          } was added successfully!`
        );
        setIsCreatingChapter(false);
        resetForm();
        handleReFetch();
        handleCloseAddChapter();
      } catch (error: any) {
        console.log(error);
        message.error(
          error?.response?.data?.detail ||
            error?.response?.data?.error ||
            "An error occured. Try again!"
        );
        setIsCreatingChapter(false);
      }
    }
  };

  //input validation for chapter
  const {
    values,
    errors,
    touched,
    handleBlur,
    resetForm,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      title: "",
      number: "",
    },
    validationSchema: CreateChapterSchema,
    onSubmit,
  });

  return (
    <>
      <Modal
        open={isOpened}
        onCancel={handleCloseAddChapter}
        footer={null}
        centered
        keyboard={false}
        maskClosable={false}
      >
        <p className="text-[26px] font-[700]">
          Add a {theType === "Movie" ? "season" : "chapter"}
        </p>

        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="number" className="text-[16px] font-[400]">
              {theType === "Movie" ? "Season number" : "Chapter number"}
              {errors.number && touched.number && (
                <small className="text-xs text-red-500">required</small>
              )}
            </label>
            <Input
              id="number"
              type="number"
              min={1}
              className={`${
                errors.number && touched.number && "border-red-500"
              } w-full h-[52px] rounded-[20px] mt-[7px]`}
              onBlur={handleBlur}
              value={values.number}
              onChange={handleChange}
              disabled={isCreatingChapter}
            />
          </div>

          <div className="mt-5">
            <label htmlFor="title" className="text-[16px] font-[400]">
              {theType === "Movie" ? "Season title" : "Chapter title"}
              {errors.title && touched.title && (
                <small className="text-xs text-red-500">required</small>
              )}
            </label>
            <Input
              id="title"
              className={`${
                errors.title && touched.title && "border-red-500"
              } w-full h-[52px] rounded-[20px] mt-[7px]`}
              onBlur={handleBlur}
              value={values.title}
              onChange={handleChange}
              disabled={isCreatingChapter}
            />
          </div>
        </form>

        <div className="mt-[30px]">
          <button
            className="w-full h-[52px] rounded-[40px] bg-gradient-to-r from-[#2E11CD] to-[#BE24BA] text-white flex justify-center items-center disabled:cursor-not-allowed"
            type="submit"
            onClick={onSubmit}
            disabled={isCreatingChapter}
          >
            {isCreatingChapter ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 24, color: "#fff" }}
                    spin
                  />
                }
              />
            ) : (
              <p className="text-[16px] font-[700]">Save</p>
            )}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default AddStoryChapter;
