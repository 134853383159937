import { useEffect, useState } from "react";
import { Empty, message, Skeleton, Spin } from "antd";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { Dropdown, Space } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { BiPencil } from "react-icons/bi";
import { BiTrash } from "react-icons/bi";
import type { MenuProps } from "antd";
import { useGeneralContext } from "../../../context/generalContext";
import { BaseServices } from "../../../helpers/baseServices";
import { global_variables } from "../../../helpers/globalVariables";
import EditExistingSkit from "../../../components/shared/publish/editExistingSkit";

const PublishedSkits = () => {
  const { reFetch, setReFetch } = useGeneralContext();
  const templateData = Array.from({ length: 4 });
  const [isFetching, setIsFetching] = useState(false);
  const [allPublished, setAllPublished] = useState([]);
  const [selectedSkit, setSelectedSkit] = useState<any>(null);
  const [isBusy, setIsBusy] = useState(false);

  const [openFinishSkit, setOpenFinishSkit] = useState(false);
  const handleCloseFinishSkit = () => {
    setOpenFinishSkit(false);
  };

  //getAllPublished
  const getAllPublished = async () => {
    setIsFetching(true);
    try {
      const response = await BaseServices.get(
        `${
          global_variables.list_audio_book
        }?limit=${4}&type=Movie&status=Published`
      );
      console.log("-----publishedSkits:", response);
      setAllPublished(response?.data?.results);
      setIsFetching(false);
    } catch (error) {
      console.log(error);
    }
  };

  //onload
  useEffect(() => {
    getAllPublished();
  }, [reFetch]);

  //handleDeleteBook
  const handleDeleteBook = async () => {
    setIsBusy(true);
    try {
      const response = await BaseServices.delete(
        `/v1/library/audio-book/${selectedSkit?.id}/delete/`
      );
      console.log("-----delSkitResponse:", response);
      setIsBusy(false);
      message.success(
        `${selectedSkit?.title || "Skit"} has been deleted successfully!`
      );
      getAllPublished();
    } catch (error: any) {
      console.log("------delSkitError:", error);
      setIsBusy(false);
      message.error(
        error?.details ||
          error?.response?.data?.error ||
          error?.response?.data?.detail ||
          "An error occured please retry!"
      );
    }
  };

  //dropdown
  const items: MenuProps["items"] = [
    {
      label: (
        <button
          className="flex gap-2 items-center text-black text-opacity-[80%]"
          onClick={() => setOpenFinishSkit(true)}
        >
          <BiPencil className="h-5 w-5" />
          <p>Edit</p>
        </button>
      ),
      key: "1",
    },
    {
      label: (
        <button
          className="flex gap-2 items-center text-red-500"
          onClick={handleDeleteBook}
        >
          <BiTrash className="h-5 w-5" />
          <p>Delete</p>
        </button>
      ),
      key: "3",
    },
  ];

  return (
    <>
      {isFetching ? (
        <>
          <div className="2xl:grid xl:grid lg:grid md:grid grid-cols-2 w-full gap-10">
            {templateData.map((item, i) => (
              <div
                key={i}
                className="py-[25px] border-t-[1px] border-t-[#D7DBE1]"
              >
                <div className="flex w-full lg:gap-[25px] gap-[15px] items-center">
                  <div className="mb-5 lg:mb-0">
                    <Skeleton.Image
                      style={{ width: 180, height: 130, borderRadius: 17 }}
                      active
                    />
                  </div>

                  <div className=" w-full">
                    <Skeleton active />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : allPublished.length === 0 ? (
        <>
          <div className="mt-10">
            <Empty description={`You have no published Skits`} />
          </div>
        </>
      ) : (
        <div className="2xl:grid xl:grid lg:grid md:grid grid-cols-2 w-full gap-10">
          {allPublished.map((item: any, i: number) => (
            <div
              key={i}
              className="pr-5 border-[1px] border-[#D7DBE1] bg-white shadow-md rounded-[20px]"
            >
              <div className="flex w-full items-center lg:gap-[25px] gap-[15px]">
                <div className="mb-5 lg:mb-0">
                  <img
                    className="lg:w-[250px] w-[150px] h-[150px] rounded-[17px] object-cover"
                    src={
                      item?.cover_image ||
                      "https://imageresizer.furnituredealer.net/img/remote/images.furnituredealer.net/img/commonimages%2Fitem-placeholder.jpg"
                    }
                    alt=""
                  />
                </div>

                <div className="flex justify-between lg:w-full">
                  <div className="text-black text-opacity-[80%]">
                    <p className="text-[14px] font-[700]">
                      Title: <span className="font-[400]">{item?.title}</span>
                    </p>

                    <p className="text-[14px] font-[700] mt-[3px]">
                      Author:{" "}
                      <span className="font-[400]">
                        {item?.author || item?.hosts}
                      </span>
                    </p>

                    <p className="text-[14px] font-[700] mt-[3px]">
                      Price:{" "}
                      <span className="font-[400]">GHS {item?.price}</span>
                    </p>

                    <p className="text-[14px] font-[700] mt-[3px]">
                      Subscribers:{" "}
                      <span className="font-[400]">
                        {item?.total_subscribers}
                      </span>
                    </p>

                    <p className="text-[14px] font-[700] mt-[3px]">
                      Total Revenue:{" "}
                      <span className="font-[400]">{item?.total_revenue}</span>
                    </p>
                  </div>

                  <div>
                    {item?.id === selectedSkit?.id && isBusy ? (
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{ fontSize: 24, color: "#000" }}
                            spin
                          />
                        }
                      />
                    ) : (
                      <Dropdown
                        className="hover:bg-gray-200 hover:rounded-full disabled:cursor-not-allowed cursor-pointer"
                        menu={{ items }}
                        trigger={["click"]}
                        placement="bottomCenter"
                        disabled={isBusy}
                      >
                        <button onClick={() => setSelectedSkit(item)}>
                          <Space>
                            <HiOutlineDotsVertical className="h-6 w-6 text-black text-opacity-[80%]" />
                          </Space>
                        </button>
                      </Dropdown>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* finish edit skit or movie */}
      {openFinishSkit && (
        <EditExistingSkit
          isOpened={openFinishSkit}
          handleClose={handleCloseFinishSkit}
          tempBookData={selectedSkit}
          handleReFetch={() => setReFetch(!reFetch)}
          theType={selectedSkit?.type}
        />
      )}
    </>
  );
};

export default PublishedSkits;
