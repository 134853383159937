import React, { useRef } from "react";

interface SampleVideoComponentProps {
  sampleVideo?: string;
  small?: boolean;
}

const SampleVideoComponent: React.FC<SampleVideoComponentProps> = ({
  sampleVideo,
  small,
}) => {
  const videoPlayerRef = useRef<any>(null);

  return (
    <>
      {small ? (
        <div className="h-[100px] w-[100px] rounded-[5px]">
          <video
            className="h-full w-full rounded-[5px] object-cover"
            src={sampleVideo}
            ref={videoPlayerRef}
            controls
          />
        </div>
      ) : (
        <div className="h-[300px] w-full rounded-[30px] bg-gradient-to-r from-[#2E11CD] to-[#BE24BA] text-white p-[10px]">
          <video
            className="h-full w-full rounded-[30px] object-cover"
            src={sampleVideo}
            ref={videoPlayerRef}
            controls
          />
        </div>
      )}
    </>
  );
};

export default SampleVideoComponent;
